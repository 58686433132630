import React, { useState } from 'react'
import styled from 'styled-components'


import spc_media from '../../assets/images/lbank_spc.png'
import close_media from '../../assets/images/lbank_close.png'
import lbank_media from '../../assets/images/lbank.png'


const StyledDiv = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    background: #000;
    p, a, span {
        color: #fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
    }
    
`;

const Content = styled.div`
    .content-image {
        padding-top: 30px;
        text-align: center;


    }

    .content-image-lbank {
        height: 100px;
        width: auto;
    }

    .content-image-x {
        height: 40px;
        width: auto;
        padding-left: 40px;
        padding-right: 50px;
    }

    .content-image-spc {
        height: 125px;
        width: auto;
    }
    
    .content-text {
        text-align: center;
        background-color: #170E32;
        width: 80%;
        margin-left: 10%;
        margin-top: 30px;
        padding-bottom: 40px;
    }

    .content-text-1 {
        font-size: 40px;
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .content-text-2 {
        font-size: 30px;
    }

    .lbank-button-wrap {
        text-align: center;
        padding-bottom: 40px;
    }

    .lbank-button {
        text-align: center;
        background-color: #D65387;
        color: #000000;
        font-size: 30px;
        border-radius: 10px;
        padding-left: 90px;
        padding-right: 90px;
        margin-top: 30px;
    }
    
   
    @media (min-width: 1441px) {
        .wapper-media {
            max-width: 1200px;
        }
    }
    @media (max-width: 1440px) {
        .wapper-media {
            max-width: 1100px;
        }
    }
    @media (max-width: 1200px) {
        .wapper-media {
            max-width: 920px;
        }
    }
    @media (max-width: 992px) {
        /* padding: 0 80px; */
        height: fit-content;

        .content-image-lbank {
            height: 50px;
        }

        .content-image-x {
            height: 20px;
            padding-left: 10px;
            padding-right: 20px;
        }

        .content-image-spc {
            height: 60px;
        }

        .content-text-1 {
            font-size: 30px;
        }

        .content-text-2 {
            font-size: 20px;
        }

    }
    @media (max-width: 768px) {
        /* padding: 0 30px; */
        .wapper-media {
            max-width: 540px;
        }
    }
    @media (max-width: 576px) {
        .content-bgr {
            > div {
                width: 400px;
                height: 400px;
                left: -200px;
            }
        }
        .wapper-media {
            padding: 0 22px;
        }
        ._right {
            > p:nth-child(2) {
                font-size: 18px;
            }
        }
    }
`;


export default function Lbank() {
    return (
    <StyledDiv>
        <Content>
            <div className='content-image'>
                <img className='content-image-lbank' src={lbank_media} alt='bgr' />
                <img className='content-image-x' src={close_media} alt='bgr' />
                <img className='content-image-spc' src={spc_media} alt='bgr' />
            </div>
            <div className='content-text'>
                <p className='content-text-1'>SPC (SPECTRE) will be listed on LBank on October 20, 2022.</p>
                <p className='content-text-2'>Deposit Time: October 19th 3:00 AM (UTC) / 11:00 AM (UTC+8)</p>
                <p className='content-text-2'>Trading starts: October 20th at 3:00 AM (UTC) / 11:00 AM (UTC+8)</p>
                <p className='content-text-2'>Withdrawal Time: October 21st 3:00 AM (UTC) / 11:00 AM (UTC+8)</p>
            </div>
            <div className='lbank-button-wrap'>
                <button className='lbank-button'>
                        <a href="https://www.lbank.info/" target="_blank" rel="noreferrer">Visit Lbank</a>
                </button>
            </div>
        </Content>
    </StyledDiv>
  )
}
