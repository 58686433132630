import React from 'react'
import styled from 'styled-components'

import Illustration from '../../assets/images/spectre_coin_large.png'
import CEO_AVATAR from '../../assets/images/avatar/ceo.png'
import PL_AVATAR from '../../assets/images/avatar/pl.png'

const StyledDiv = styled.div`
    width: 100%;
    height: fit-content;
    padding-bottom: 2px;
    /* padding-bottom: 34px; */
    position: relative;
    background: linear-gradient(156deg, #30195B 0.08%, #000000 90.08%);
    p, a, span {
        color: #fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
    }
    .feat-decs {
        text-align: center;
        margin: 0 auto;
        p {
            font-weight: 700;
            font-size: 40px;
            line-height: 150%;
            padding: 50px 0 10px;
        }
        span {
            font-size: 24px;
            line-height: 35px;
            letter-spacing: 0.01em;
            color: #E0E0E0;
        }
    }
    .feat-bgr {
        .bgr-1 {
            width: 931px;
            height: 931px;
            position: absolute;
            left: -470px;
            background: conic-gradient(from 120.06deg at 50% 50%, rgba(255, 255, 255, 0.08) 0deg, rgba(255, 255, 255, 0) 360deg);
            transform: rotate(-120deg);
            border-radius: 50%;
        }
        .bgr-2 {
            width: 342px;
            height: 686px;
            position: absolute;
            /* top: 660px; */
            bottom: 0;
            right: 0;
            background: conic-gradient(from 180deg at 100% 50%, rgba(255, 255, 255, 0.04) 0deg, rgba(255, 255, 255, 0) 360deg);
            /* transform: rotate(-120deg); */
            border-top-left-radius: 343px;
            border-bottom-left-radius: 343px;
        }
        .bgr-3 {
            width: 500px;
            height: 500px;
            position: absolute;
            bottom: -129px;
            left: 20%;
            background: conic-gradient(from 0deg at 50% 54%,rgba(255,255,255,0.06) 0deg,rgba(255,255,255,0) 360deg);
            border-radius: 50%;
        }
    }
    @media (min-width: 1441px) {
        .feat-decs {
            max-width: 1200px;
            margin: auto;
        }
        .feat-bgr {
            .bgr-2 {
                width: 450px;
                height: 650px;
                border-top-left-radius: 325px;
                border-bottom-left-radius: 325px;

            }
        }
    }
    @media (max-width: 1440px) {
        .feat-decs {
            max-width: 1100px;
        }

    }
    @media (max-width: 1200px) {
        .feat-decs {
            max-width: 920px;
        }
        .feat-bgr {
            .bgr-3 {
                width: 450px;
                height: 450px;
            }
        }
    }
    @media (max-width: 992px) {
        .feat-decs {
            max-width: 720px;

        }
        .feat-bgr {
            .bgr-1 {
                width: 650px;
                height: 650px;
                left: -325px;
                top: 225px;
            }
            .bgr-2 {
                width: 200px;
                height: 400px;
                top: 624px;
                border-top-left-radius: 200px;
                border-bottom-left-radius: 200px;
            }
            .bgr-3 {
                bottom: -4%;
                left: 10%;
                width: 400px;
                height: 400px;
            }
        }
    }
    @media (max-width: 768px) {
        .mh-wapper {
            min-height: 515px;
        }
        .feat-decs {
            max-width: 540px;
        }
        .feat-bgr {
            .bgr-1 {
                width: 400px;
                height: 400px;
                left: -200px;
                top: 540px;
            }
            .bgr-2 {
                width: 200px;
                height: 400px;
                /* top: 1024px; */
                border-top-left-radius: 200px;
                border-bottom-left-radius: 200px;
            }
            .bgr-3 {
                display: none;
            }
        }
    }
    @media (max-width: 576px) {
        height: fit-content;
        padding-bottom: 2px;
        .feat-decs {
            margin: 0 22px;
        }
        .feat-bgr {
            .bgr-1 {
                top: 660px;
            }
        }
    }
    @media (max-width: 460px) {
        .feat-bgr {
            .bgr-1 {
                width: 200px;
                height: 200px;
                left: -100px;
                top: 540px;
            }
            .bgr-2 {
                width: 100px;
                height: 200px;
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px;
            }
        }
    }
`;

const Banner = styled.div`
    height: 100%;
    min-height: 430px;
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 20px;
    margin: 60px auto;
    .wapper {
        display: flex;
        padding: 0 90px;
        position: absolute;
        height: 100%;
        &.our-team {
            flex-direction: column;
            position: static;
            .banner-info {
                width: auto;
            }
            .team-info {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                margin-top: 2rem;
                .member {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 2rem;
                    .role {
                        color: #bf0052;
                    }
                    img {
                        width: 200px;
                    }
                    .link {
                        i {
                            color: #0060c8;
                            font-size: 1.5em;
                        }
                    }
                }
            }
        }
    }
    .reverse-content {
        flex-direction: row-reverse;
        .banner-img {
            justify-content: flex-start;
        }
    }
    .banner-info {
        width: 40%;
        margin: auto;
        > p {
            font-weight: 700;
            font-size: 40px;
            line-height: 150%;
            padding-top: 20px;
        }
        > span {
            display: block;
            font-size: 20px;
            line-height: 28px;
            margin: 24px 0 40px 0;
        }
        button {
            width: 224px;
            height: 60px;
            background-color: #060528;
            background: linear-gradient(90.19deg, #DA0783 6.13%, #A710C4 96.02%);
            border-radius: 5px;
            color: #fff;
            font-weight: 700;
            font-size: 18px;
            margin: 16px 0;
            border: none;
            cursor: pointer;

            &:hover {
                background: linear-gradient(100.3deg, #FF0051 -18.75%, #AF10BE 136.84%);
                border: none;
            }
            > a {
                color: #fff;
                font-weight: 700;
                font-size: 18px;
            }
        }
    }
    .banner-img {
        width: 60%;
        display: flex;
        margin: auto;
        justify-content: flex-end;
        > img {
            width: 90%;
            max-width: 400px;
        }
    }
    @media (min-width: 1441px) {
        max-width: 1200px;
        margin: 40px auto;

    }
    @media (max-width: 1440px) {
        max-width: 1100px;
    }
    @media (max-width: 1200px) {
        max-width: 920px;
    }
    @media (max-width: 992px) {
        max-width: 720px;
        /* min-height: 330px; */
        .wapper {
            padding: 0 34px;
            .banner-info {
                width: 50%;
            }
            .banner-img {
                width: 50%;
            }
        }
    }
    @media (max-width: 768px) {
        margin: 34px auto;
        max-width: 540px;
        .wapper {
            .banner-info {
                > span {
                    font-size: 18px;
                }
            }
        }
    }
    @media (max-width: 576px) {
        height: fit-content;
        max-width: 100%;
        margin: 34px 22px;
        .wapper {
            display: block;
            padding: 20px 34px;
            position: initial;
            .banner-info {
                width: 100%;
                text-align: center;
                > button {
                    width: 90%;
                    height: 70px;
                }
            }
            .banner-img {
                width: 100%;
                justify-content: center;
            }
        }
    }
`;

export default function Features() {
  return (
    <StyledDiv className='scroll'>
        <div className='feat-bgr'>
            <div className='bgr-1'></div>
            <div className='bgr-2'></div>
            {/* <div className='bgr-3'></div> */}
        </div>
        <div className='feat-decs'>
            <p>Everyone can participate</p>
            <span>There are plenty of opportunities for people to contribute to the Spectre, even if they have no technical background.</span>
        </div>
        <Banner>
            <div className='wapper'>
                <div className='banner-info'>
                    <p>SPECTRE community</p>
                    <span>Our community is the key to Spectre future, and we'd love for you to join us. Follow along, chat or read up on what we’re doing.</span>
                    {/* <button><a>Get Started</a></button> */}
                </div>
                <div className='banner-img'>
                    <img src={Illustration} alt='coin' loading="lazy"/>
                </div>
            </div>
        </Banner>
        <Banner className='mh-wapper'>
            <div className='wapper reverse-content'>
                <div className='banner-info'>
                    <p>Become a Validator</p>
                    <span>Spectre uses the PoS consensus algorithm. Consensus is maintained by network participants "validators" who check the validity of blocks. Learn about a validator.</span>
                    <button><a  href='https://head2head.gitbook.io/spectre-chain/consensus-and-validator-quorum/validator-quorum'
                                target="_blank"
                                rel="noreferrer"
                            >Read more</a></button>
                </div>
                <div className='banner-img'>
                    <img src={Illustration} alt='coin' loading="lazy"/>
                </div>
            </div>
        </Banner>
        <Banner>
            <div className='wapper'>
                <div className='banner-info'>
                    <p>Staking on SPECTRE</p>
                    <span>Token holders, including the validators, can put their tokens “bonded” into the stake.</span>
                    <button><a  href='https://head2head.gitbook.io/spectre-chain/staking-and-governance/staking-on-spectre'
                                target="_blank"
                                rel="noreferrer"
                            >Read more</a></button>
                </div>
                <div className='banner-img'>
                    <img src={Illustration} alt='coin' loading="lazy"/>
                </div>
            </div>
        </Banner>
    </StyledDiv>
  )
}
