import React from "react";
import { Modal } from "antd";
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
    border-radius: 20px;
    max-width: 1100px;
    width: 90% !important;
    @media (max-width: 1200px) {
        width: 70% !important;
    }
    @media (max-width: 576px) {
        width: 96% !important;    
    }
    .ant-modal-content {
        border-radius: 24px;
        .ant-modal-close {
            color: #fff;
            &:hover {
                opacity: 0.7;
                > span {
                    font-size: 18px;
                }
            }
        }
    }
    .video {
        border-radius: 20px;
        margin: -2px;
        .react-player {
            height: 650px !important;
            @media (max-width: 1200px) {
                height: 450px !important;
                /* width: 75% !important; */
            }
            @media (max-width: 576px) {
                height: 350px !important;
            }
        }
    }
    iframe {
        /* height: 500px; */
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
`;

const VideoModal = ( props ) => {

    const hideModal = () => {
        props.setOpenVideo(false);
    };
    const URL = "https://www.youtube.com/watch?v=YVgfHZMFFFQ";
    return (
        <StyledModal
            title=""
            visible={props.openVideo}
            footer={null}
            onCancel={hideModal}
            bodyStyle={{ padding: 1 }}
            // width={'auto'}
        >
        {/* <video width="480" height="320" controls className='content' >
            <source src="https://www.youtube.com/watch?v=MbGYp5yA0y4" type="video/mp4" />
        </video> */}
        <div className="video" style={{paddingTop: '50px', background: "#232323"}}>
            <ReactPlayer
                className="react-player"
                width={'100%'}
                controls={true}
                url={URL} />
        </div>
        </StyledModal>
    );
}

export default VideoModal;