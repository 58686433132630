import React, {useState} from 'react'
import styled from 'styled-components'
import { DownOutlined, BarsOutlined, CloseOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Space } from 'antd';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.min.css';
// import i18next from 'i18next';
// import { useTranslation } from 'react-i18next'

// import Earth_icon from '../../assets/images/icons/earth_icon.svg'
import Spectre_icon from '../../assets/images/icons/Spectre_chain_logo_color.png'


export const HeaderMenu = styled.div`
    display: flex;
    position: relative;
    padding-top: 42px;
    margin: 0 126px;
    justify-content: space-between;
    p {
        font-size: 24px;
    }
    .ant-space-item a {
        font-size: 24px;
    }

    .menu-info {
        display: flex;
        width: fit-content;
        > p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            line-height: 22px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            margin: auto;
            padding-left: 8px;
            &:hover {
                    color: #FF0052;
                    cursor: pointer;
                }
        }

    }
    /* .ant-dropdown {
        width: 150px;
    } */   
    .menu-link {
        display: flex;
        > span {
            margin-left: 4px;
            font-size: 24px;
            margin: auto;
        }
        .link-item {
            margin: auto 10px;
            display: flex;
            > span {
                margin-left: 6px;
                font-size: 24px;
                margin: auto;
            }
            &:hover {
            a, span, p {
                    color: #FF0052;
                    cursor: pointer;
                }
            }
        }
        .change-language {
            margin: auto;
            margin-left: 34px;
        }
        .popup-change {
            width: 300px;
            height:300px;
            background-color: #FF0052;
        }

    }
    .menu-link-bar {
        display: none;
        .menu-bar-close {
                display: none;
            }
    }
    .menu-mobile {
        display: none;
        width: calc(100% + 60px );
        /* height: 700px; */
        background: linear-gradient(110.27deg, #08073D 12.69%, #000000 98.1%, rgba(0, 0, 0, 0) 98.11%);
        position: absolute;
        top: 90px;
        z-index: 99;
        > ul {
            width: 100%;
            padding: 0;
            margin: 0;
            border-top: 1px solid rgba(204, 204, 204, 0.3);
            > li {
                display: flex;
                width: 100%;
                color: #fff;
                height: 72px;
                border-bottom: 1px solid rgba(204, 204, 204, 0.3);
                text-align: left;
                padding: 0 24px;
                cursor: pointer;
                box-sizing: border-box;
                &:hover {
                    > a {
                        color: #FF0052;
                        /* font-size: 24px; */
                    }
                    img, span {
                        opacity: 0.7;
                    }
                }
                > a {
                    font-size: 24px;
                    margin: auto 0;
                    font-weight: 900;
                }
                .ant-dropdown-trigger {
                    margin: auto 0;
                }
            }
        }
    }
    .active-btn {
        display: block;
    }
    .active {
        display: block;
        animation: spin 0.5s ease;

    }
    .close {
        display: none;
        
    }

    @keyframes spin { 
        from { 
            top: -20%;
            opacity: 0.2;
        } to { 
            top: 90px;
            opacity: 1;
        }
    }
    /* PC */
    @media (max-width: 1220px) {
        margin: 0 80px;
    }
    /* table */
    @media (max-width: 992px) {
        justify-content: center;
        margin: 0 32px;
        .menu-link {
            display: none;
        }
        .menu-info {
        }
        .menu-link-bar {
            display: block;
            position: absolute;
            left: 0;
            cursor: pointer;
            &:hover {
            }
            > span {
                font-size: 26px;
            } 
        }
    }
    /* table */
    @media (max-width: 768px) {
        margin: 0 30px;
    }
`;

const StyledCommunity = styled(Menu)`
    width: 150px;
    background: linear-gradient(156deg,#30195B 0.08%,#000000 90.08%);
    .ant-dropdown-menu-item {
        &:hover {
            span {
                > i, p ,p > a {
                    color: #000;
                }
            }
        }
        font-size: 18px;
        .ant-dropdown-menu-title-content {
            display: flex;
            margin: auto;
            > i, p {
                color: #fff;
                margin: auto 0;
            }
            > p {
                padding-left: 6px;
                > a {
                    color: #fff;
                }
            }
        }
    }

`;

// const StyledMenu = styled(Menu)`
//     width: 150px;
//     p {
//         margin: auto;
//     }
// `;

// const menu = (
//     <StyledMenu
//     items={[
//         {
//             label: <p onClick={() => i18next.changeLanguage('en')}>English</p>,
//             key: '0',
//         },
//         {
//             label: <p onClick={() => i18next.changeLanguage('jp')}>Japan</p>,
//             key: '1',
//         },
//         {
//             label: <p onClick={() => i18next.changeLanguage('zh')}>China</p>,
//             key: '3',
//         },
//     ]}
//     />
// );

const community = (
    <StyledCommunity
    items={[
        {
            label:
            <>
                <i className="fa-brands fa-telegram"></i>
                <p>
                    <a  href='https://t.me/Spectre_blockchain'
                        target="_blank" 
                        rel="noreferrer"
                    >Telegram</a>
                </p>
            </>,
            key: '0',
        },
        {
            label:
            <>
                <i className="fa-brands fa-reddit"></i>
                <p>Reddit</p>
            </>,
            key: '1',
        },
        {
            label:
            <>
                <i className="fa-brands fa-twitter"></i>
                <p>
                    <a  href='https://twitter.com/Spectre_Chain'
                        target="_blank" 
                        rel="noreferrer"
                    >Twitter</a>
                </p>
            </>,
            key: '3',
        },
    ]}
    />
);



const MenuSpectre = () => {
    
    const [isActive, setActive] = useState(false)

    const handleClick = () => {
        setActive(!isActive)
    }

    return (
    <HeaderMenu>
            <Link to="/">
                <div className='menu-info'>
                    <img src={Spectre_icon} alt='logo' />
                    <p>SPECTRE CHAIN</p>
                </div>
            </Link>
            <div className='menu-link'>
                {/* <div className='link-item'>
                    <a>Developers</a>
                    <DownOutlined />
                </div> */}
                <Link to="/network" style={{margin: 'auto'}}>
                    <div className='link-item'>
                        <p>Network</p>
                        {/* <DownOutlined /> */}
                    </div>
                </Link>
                {/* <div className='link-item'>
                    <a>Validator</a>
                    <DownOutlined />
                </div> */}
                <div className='link-item'>
                    {/* <a>Community</a>
                    <DownOutlined /> */}
                    <Dropdown overlay={community} trigger={['click']}>
                            <div onClick={(e) => e.preventDefault()}>
                            <Space>
                                <a>Community</a>
                                <DownOutlined style={{marginTop: '8px', fontSize: '24px'}} />
                            </Space>
                            </div>
                    </Dropdown>
                </div>
                {/* <div className='link-item'>
                    <a>エコシステム</a>
                </div> */}
                {/* <div className='change-language'>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <img src={Earth_icon} alt='earth' />
                            <DownOutlined />
                        </Space>
                        </a>
                    </Dropdown>
                </div> */}
            </div>
            <div className='menu-link-bar' onClick={handleClick}>
                <BarsOutlined className={(isActive ? 'close' : '')}/>
                <CloseOutlined className={"menu-bar-close" + (isActive ? 'active-btn' : '')}/>
            </div>
            <div className={"menu-mobile " + (isActive ? 'active' : '')}>
                <ul>
                    {/* <li><a>Developers</a></li> */}
                    {/* <li><a>Network</a></li> */}
                    <li>
                    <Link to="/network">
                            <p>Network</p>
                    </Link>
                    </li>
                    {/* <li><a>Validator</a></li> */}
                    {/* <li><a>Community</a></li> */}
                    <li>
                        <Dropdown overlay={community} trigger={['click']}>
                            <div onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <a>Community</a>
                                    <DownOutlined style={{marginTop: '8px', fontSize: '12px'}} />
                                </Space>
                            </div>
                        </Dropdown>
                    </li>
                    {/* <li><a>エコシステム</a></li> */}
                    {/* <li>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <img src={Earth_icon} alt='earth' />
                                    <DownOutlined style={{marginTop: '12px'}} />
                                </Space>
                            </a>
                        </Dropdown>
                    </li> */}
                </ul>
            </div>
        </HeaderMenu>
    )
}

export default MenuSpectre;
