import React from 'react'
import styled from 'styled-components'

// import { Menu } from 'antd';
// import 'antd/dist/antd.min.css';
// import i18next from 'i18next';

import { useTranslation } from 'react-i18next'

import Spectre_group from '../../assets/images/Group_spectre.png'
import Loading_icon from '../../assets/images/icons/path_loading.svg'
import MenuSpectre from '../menu/menu';


export const StyledDiv = styled.div`
    position: relative;
    z-index: 1;
    height: 97vh;
    width: 100%;
    min-height: 784px;
    /* margin: 0 126px; */

    a {
        text-decoration: none;
    }
    @media (min-width: 1530px) {
        min-height: 878px !important;
    
    }
    @media (min-width: 1420px) {
        min-height: 830px;
    }
    @media (max-width: 992px) {    
        height: 100%;
    }
    @media (max-width: 380px) {    
        height: 1300px;
    }
    /* @media (max-width: 470px) {    
        height: 1400px;
    } */

    background: linear-gradient(110.27deg,#08073D 12.69%,#000000 100%,rgba(0,0,0,0) 100%);
    p, a, span, button {
        color: #fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
    }
    a {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.01em;
    }
`;

const Content = styled.div`
    display: flex;
    margin: 0 126px;
    /* margin: auto; */
    position: relative;
    padding-top: 20px;
    ._left {
        width: 55%;
        margin-top: 75px;
        .title {
            p, code {
                font-weight: 700;
                font-size: 48px;
                line-height: 60px;
            }
        }
        .decs {
            margin: 40px 0 50px 0;
            max-width: 85%;
            p {
                color: #E0E0E0;
                font-size: 24px;
            }
        }
        .group-btn {
            display: flex;
            justify-content: space-between;
            max-width: 500px;
            button {
            width: 45%;
            height: 60px;
            position: relative;
            background-clip: padding-box;
            border: 2px solid transparent;
            background-color: #060528;
            border-radius: 5px;
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            cursor: pointer;    
            /* &:focus, */
                &:hover {      
                    background: linear-gradient(100.3deg, #FF0051 -18.75%, #AF10BE 136.84%);
                    border: none;
                }
                > a {
                    color: #fff;
                    font-weight: 700;
                    font-size: 24px;
                    text-decoration: none;
                }
            }
            button::before {
                content: "";
                position: absolute;
                inset: 0;
                z-index: -1;
                margin: -2px;
                background-image: linear-gradient(91.11deg, #FF0052 3.85%, #A710C4 77.45%);
                border-radius: inherit;
            }
            /* button:last-child {
                margin-left: 26px;
            } */
        }
    }
    ._right {
        width: 47%;
        /* position: relative; */
        display: flex;
        justify-content: flex-end;
        > img {
            width: 100%;
            max-width: 600px;
        }
    }
    @media (min-width: 1751px) {
        /* max-width: 1660px; */
        ._left {
            .title {
                p, code {
                    font-size: 60px;
                    line-height: 70px;
                }
            }
        }
    }
    @media (max-width: 1750px) {
        ._left {
            .title {
                p, code {
                    font-size: 48px;
                    line-height: 58px;
                }
            }
        }
    }
    @media (max-width: 1540px) {
        /* max-width: 1100px; */
        /* margin: 0 126px; */
        ._left {
            .title {
                p, code {
                    font-size: 44px;
                    line-height: 54px;
                }
            }
            .decs {
            margin: 30px 0 30px 0;
            }
        }
    }
    @media (max-width: 1340px) {
        /* max-width: 920px; */
        ._left {
            width: 55%;
            .title {
                p, code {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        }
        ._right {
            width: 45%;
        }
    }
    @media (max-width: 1220px) {
        /* max-width: 920px; */
        margin: 0 80px;
        ._left {
            .title {
                p, code {
                    /* font-size: 54px; */
                }
            }
            .decs {
                max-width: 86%;
            }
        }
    }
    @media (max-width: 1120px) {
        ._left {
            .title {
                p, code {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
        }
    }
    @media (max-width: 992px) {
        display: block;
        /* max-width: 720px; */
        /* height: 1210px; */
        /* margin: 0 80px; */
        ._left {
            width: 100%;
            .title {
                p, code {
                    font-size: 48px;
                    line-height: 58px;
                }
            }
            .group-btn {
                max-width: none;
                justify-content: space-between;
                > button {
                    height: 70px;
                    max-width: 270px;

                    border-radius: 10px;
                    background: transparent;
                    border-left: 2px solid #FF0052;
                    border-right: 2px solid #A710C4;
                    background-image: linear-gradient(90deg, #FF0052, #A710C4), linear-gradient(90deg, #FF0052, #A710C4);
                    background-size: 100% 2px;
                    background-position: 0 0, 0 100%;
                    background-repeat: no-repeat;
                }
                > button::before {
                    display: none;
                }
            }
        }
        ._right {
            width: 100%;
            padding-bottom: 120px;
            z-index: -1;
            margin-top: -25%;
            > img {
                max-width: none;
            }
        }
    }
    @media (max-width: 800px) {
        ._left {
            .title {
                p, code {
                    font-size: 44px;
                    line-height: 54px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        /* margin: 0 30px; */
        /* max-width: 540px; */
        ._left {
            .title {
                p, code {
                    font-size: 36px;
                    line-height: 44px;
                }
            }
            .group-btn {
                justify-content: space-between;
            }
        }
    }
    @media (max-width: 640px) {
        ._left {
            .title {
                p, code {
                    font-size: 32px;
                    line-height: 40px;
                }
            }
        }
    }
    @media (max-width: 576px) {
        margin: 0 22px;
        max-width: 100%;
        ._left {
            .decs {
                max-width: none;
                p {
                    font-size: 20px;
                }
            }
        }
        ._right {
            padding-bottom: 170px;
        }
    }
    @media (max-width: 450px) {
        ._left {
            .title {
                p, code {
                    font-size: 26px;
                    line-height: 36px;
                }
            }
        }
    }
    @media (max-width: 453px) {
        ._right {
            padding-bottom: 180px;
        }
    }
    @media (max-width: 443px) {
        ._right {
            padding-bottom: 240px;
        }
    }

`;

const FooterContent = styled.div`
    width: 100%;
    min-height: 131px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: linear-gradient(91.11deg, #FF0052 3.85%, #A710C4 77.45%);
    padding: 0 126px;
    margin-top: 10px;
    @media (min-width: 993px) and (max-width: 1081px) {
        padding: 0 70px ;

    }
    .content {
        width: fit-content;
        display: flex;
        margin: auto 0;
        justify-content: center;
        max-width: 50%;
        > img {
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.22);
            padding: 10px;
            border-radius: 50%;
            margin: auto 0;
            box-sizing: content-box;
        }
        .content-decs {
            margin: auto;
            margin-left: 9px;
            > p {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                /* line-height: 150%; */
            }
            > span {
                font-family: 'Rubik';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: #E0E0E0;
            }
        }
    }
    .content:first-child {
        justify-content: flex-start;
    }
    .content:last-child {
        justify-content: flex-end;
    }
    .wapper-footer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: auto;
    }

    @media (max-width: 1440px) {
        padding: 0 5%;
    }
    @media (max-width: 992px) {
        /* padding: 0 0 0 80px; */

        .content {
            justify-content: flex-start !important;
            min-width: 306px;
            > img {

            }
            .content-decs {
                > p {
                    font-size: 20px;
                }
                > span {
                    font-size: 14px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        /* padding: 0 0 0 30px; */
        .content {
            min-width: 242px;
        }
    }
    @media (max-width: 576px) {
        padding: 0 0 0 6px;
        .content {
            min-width: 184px;
            .content-decs {
                /* height: 86px; */
                padding-top: 20px;
                margin-bottom: 10px;
                > p {
                    /* font-size: 20px; */
                }
                > span {
                    font-size: 16px;
                }
            }
        }
    }
`;

const Header = () => {
    const { t } = useTranslation()

return (
    <StyledDiv>
        <MenuSpectre />
        <Content>
            <div className='_left'>
                <div className='title'>
                    <p><code style={{color: '#FF0075'}}>
                        {/* {t('home_page.header.powerful')} */}
                        High-Efficiency
                    </code> and <code style={{color: '#FF0075'}}>Energy-Saving.</code>
                     {/* {t('home_page.header.for_developers')}.  */}
                    <code style={{ color: '#2438DF'}}>Ultra-Fast</code> and Powerful for everyone.</p>
                </div>
                <div className='decs'>
                    <p>{t('home_page.header.description')}</p>
                </div>
                <div className='group-btn'>
                    <button>
                        <a href="https://head2head.gitbook.io/spectre-chain/" target="_blank" rel="noreferrer">Whitepaper</a>
                    </button>
                    {/* <button>Read Documentation</button> */}
                </div>
                <div className='circle '>

                </div>
            </div>
            <div className='_right'>
                <img src={Spectre_group} alt='logo-spactre'/>
            </div>
        </Content>
        <FooterContent>
            {/* <div className='wapper-footer'> */}
                <div className='content'>
                    <img src={Loading_icon} alt='loading' />
                    <div className='content-decs'>
                        <p>2,000</p>
                        <span>Transactions per second</span>
                    </div>
                </div>
                <div className='content'>
                    <img src={Loading_icon} alt='loading' />
                    <div className='content-decs'>
                        <p>3s</p>
                        <span>Blocktime</span>
                    </div>
                </div>
                <div className='content'>
                    <img src={Loading_icon} alt='loading' />
                    <div className='content-decs'>
                        <p>$0.000021</p>
                        <span>Avg. cost per transaction</span>
                    </div>
                </div>
                <div className='content'>
                    <img src={Loading_icon} alt='loading' />
                    <div className='content-decs'>
                        <p>21</p>
                        <span>Validator nodes</span>
                    </div>
                </div>
            {/* </div> */}
        </FooterContent>
    </StyledDiv>
  )
}

export default Header;
