import React, { useState } from 'react'
import styled from 'styled-components'

import VideoModal from '../modal/videoModal'

import Vector_media from '../../assets/images/vector-media.svg'
// import Vector_media_mobile from '../../assets/images/vector_vector.svg'
import Group_media from '../../assets/images/Group_media.png'


const StyledDiv = styled.div`
    position: relative;
    width: 100%;
    height: fit-content;
    background: #000;
    p, a, span {
        color: #fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
    }
    
`;

const Content = styled.div`
    position: relative;
    height: 510px;
    background: #180E34;
    /* padding: 0 126px; */
    p {
        font-size: 30px;
    }
    .content-bgr {
        width: 100%;
        position: absolute;
        right: 0;
        div {
            width: 686px;
            height: 686px;
            position: absolute;
            left: -100px;
            top: 164px;
            background: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0.08) 0deg, rgba(255, 255, 255, 0) 360deg);
            border-radius: 50%;
        }
        .content-bgr-pc {
            position: absolute;
            right: 0;
        }
        /* .content-bgr-mb {
            display: none;
            position: absolute;
            right: 0;
        } */
    }
    ._left {
        width: 60%;
        margin: auto;
        z-index: 1;
        img {
            width: 100%;
            max-width: 510px;
        }
    }
    ._right {
        width: 40%;
        margin: auto;
        z-index: 1;
        p:first-child {
            font-weight: 700;
            font-size: 40px;
        }
        p:last-child {
            font-size: 16px;
            color: #E0E0E0;
        }
        button {
            width: 224px;
            height: 60px;
            background-color: #060528;
            background: linear-gradient(90.19deg, #DA0783 6.13%, #A710C4 96.02%);
            border-radius: 5px;
            color: #fff;
            font-weight: 700;
            font-size: 24px;
            margin-top: 16px;
            border: none;
            cursor: pointer;    

            &:hover {      
                background: linear-gradient(100.3deg, #FF0051 -18.75%, #AF10BE 136.84%);
                border: none;
            }
        }

    }
    .wapper-media {
        width: 100%;
        height: 100%;
        display: flex;
        margin: auto;
    }
    @media (min-width: 1441px) {
        .wapper-media {
            max-width: 1200px;
        }
    }
    @media (max-width: 1440px) {
        .wapper-media {
            max-width: 1100px;
        }
    }
    @media (max-width: 1200px) {
        .wapper-media {
            max-width: 920px;
        }
    }
    @media (max-width: 992px) {
        /* padding: 0 80px; */
        height: fit-content;
        .wapper-media {
            max-width: 720px;
            display: block;
            height: fit-content;
        }
        .content-bgr {
            width: 100%;
            height: 100%;
            .content-bgr-pc {
                bottom: 0;
            }
            .content-bgr-mb {
                display: block;
            }
            > div {
                transform: rotate(180deg);
                left: -343px;
                top: 8%;
            }
        }
        ._left {
            width: 100%;
            display: flex;
            position: relative;
            padding-top: 20px;
            > img {
                margin: auto;
                max-width: 580px;
            }
        }
        ._right {
            width: 100%;
            height: 400px;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            > p:nth-child(2) {
                margin: 26px 0 10px 0;
            }
        }

    }
    @media (max-width: 768px) {
        /* padding: 0 30px; */
        .wapper-media {
            max-width: 540px;
        }
    }
    @media (max-width: 576px) {
        .content-bgr {
            > div {
                width: 400px;
                height: 400px;
                left: -200px;
            }
        }
        .wapper-media {
            padding: 0 22px;
        }
        ._right {
            > p:nth-child(2) {
                font-size: 18px;
            }
        }
    }
`;


export default function WhyCrappo() {

            // state = { visible: false };
    const [openVideo, setOpenVideo] = useState(false)

    const handleVideo = () => {
        setOpenVideo(!openVideo)
    }

    return (
    <StyledDiv>
        <Content>
            <div className='content-bgr'>
                <img className='content-bgr-pc' src={Vector_media} alt='bgr' />
                {/* <img className='content-bgr-mb' src={Vector_media_mobile} alt='bgr-mobile' /> */}
                <div></div>
            </div>
            <div className='wapper-media'>
                <div className='_left'>
                    <img src={Group_media} alt='media' />
                </div>
                <div className='_right'>
                    <p>About Spectre</p>
                    <p>Learn more about Spectre and see what makes it great.</p>
                    <button onClick={handleVideo}>Watch video</button>
                </div>
            </div>
            {/* <VideoModal openVideo={openVideo} setOpenVideo={setOpenVideo}/> */}
            { openVideo && <VideoModal  openVideo={openVideo} setOpenVideo={setOpenVideo} /> }
        </Content>
    </StyledDiv>
  )
}
