import React from 'react'
import styled from 'styled-components';

import Banner from '../../assets/images/banner.png'
import BannerMobile from '../../assets/images/banner_mobile.png'
import CoinSpectre from '../../assets/images/icons/spectre_coin.png'
import VectorBg from '../../assets/images/Vector_10.svg'
import BgrVector from '../../assets/images/bgr_vector_path.svg'



const StyledDiv = styled.div`
    height: fit-content;
    padding-bottom: 48px;
    position: relative;
    background: radial-gradient(93.79% 134.84% at 50% 34.29%, #000000 0%, #03000A 100%);
    span, a, p {
        color: #fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
    }
    .bgr {
        width: 100%;
        height: 100%;
        position: absolute;
        .vector_1 {
            width: 100%;
            position: absolute;
            bottom: 0;
        }
        .vector_2 {
            width: 100%;
            position: absolute;
        }
        .bgr-1 {
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(155.7deg, rgba(205, 93, 174, 0.18) 0.74%, rgba(33, 50, 201, 0.18) 92.04%);
        }
    }
    .wapper-coin {
        display: flex;
        /* flex-direction: column; */
        flex-wrap: wrap;
    }
    .decs {
        z-index: 1;
        position: relative;
        > p:first-child {
            padding: 64px 0 32px 0;
            font-weight: 700;
            font-size: 40px;
            line-height: 150%;
            text-align: center;
        }
        > p:last-child {
            font-size: 20px;
            line-height: 170%;
            text-align: center;
        }
    }

    . coin-desc-4 {
        padding-top:20px;
    }
    .content {
        width: 100%;
        position: relative;
        z-index: 1;
        .content-banner-pc {
            display: flex;
            margin: auto;
            padding: 90px 0;
            max-width: 840px;
            width: 100%;

        }
        .content-banner-mb {
            display: none;
        }
        > div {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
        }
    }
    @media (min-width: 1441px) {
        .decs {
            max-width: 1200px;
            margin: auto;
        }
        .content {
            max-width: 1200px;
            margin: auto;
            > div {
                margin: 0;
            }
        }
    }
    @media (max-width: 1440px) {
        .decs, .content {
            max-width: 1100px;
            margin: auto;

        }
        .content {
            position: relative;
        }
    }
    @media (max-width: 1200px) {
        height: fit-content;
        padding-bottom: 48px;
        .decs, .content {
            max-width: 920px;
        }
    }
    @media (max-width: 992px) {
        height: fit-content;
        padding-bottom: 24px;
        .decs, .content {
            max-width: 720px;
        }
        .content {

            > div {
                flex-direction: column;
                align-items: center;
            }
        }
    }
    @media (max-width: 768px) {
        height: fit-content;
        .decs, .content {
            max-width: 540px;
        }
        .content {
            .content-banner-pc {
                display: none;
            }
            .content-banner-mb {
                display: block;
                width: 100%;
                margin: 40px auto;
            }
            > div {
                /* margin: 0 30px; */
            }
        }
    }
    @media (max-width: 576px) {
        height: fit-content;
        padding-bottom: 24px;
        .decs {
            max-width: 100%;
            margin: 0 22px;
        }
        .content {
            padding: 0 22px;
        }
    }
`;

const Coin = styled.div`
    width: 46%;
    /* min-width: 500px; */
    position: relative;
    background: #1B1426;
    border-radius: 16px;
    background-clip: padding-box;
    border: 2px solid transparent;
    margin: 34px 0;

    &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: -1;
            margin: -2px;
            background: linear-gradient(135.64deg, #CC5DAE 1.74%, #2132CD 93.91%);
            border-radius: inherit;
        }
    .coin-decs {
        margin-top: 44px;
        padding: 0 32px;
        > p:first-child {
            font-weight: 700;
            font-size: 40px;
            text-align: center;
            line-height: 150%;
        }
        > p:last-child {
            text-align: center;
            font-size: 20px;
            margin-top: 16px;
        }
    }
    > img {
        width: 70%;
        margin: auto;
        margin-bottom: 10px;
        display: flex;
        padding: 12%;
    }

    .coin-4-img {
        padding-top: 155px;
    }

    @media (max-width: 992px) {
        width: 100%;
        max-height: 700px;
        margin: 20px 0;
        margin-bottom: 40px;
    }
    @media (max-width: 992px) {
        .coin-decs {
            > p:first-child {
                font-size: 40px;
            }
            > p:last-child {
                font-size: 18px;
            }
        }
    }
`;


export default function Bg() {
  return (
    <StyledDiv>
        <div className='bgr'>
            <img  className='vector_1' src={BgrVector} alt='bgr' />
            <img className='vector_2' src={VectorBg} alt='bgr' />
            {/* <div className='bgr-1' /> */}
        </div>
        <div className='decs'>
            <p>SPECTRE's mission</p>
            <p>Spectre is not only a public chain, but also to focus on the discovery and support of high-potential developers and innovative projects. Relying on the world's largest trading ecosystem, SPECTRE is committed to becoming the birthplace of innovative technologies and innovative businesses, and building a complete ecological loop of technology development, application promotion, and trading.</p>
        </div>
        <div className='content'>
            <img className='content-banner-pc' src={Banner} alt='banner' loading="lazy"/>
            <img className='content-banner-mb' src={BannerMobile} alt='banner' loading="lazy"/>
            <div className='wapper-coin'>
                <Coin>
                        <div className='coin-decs'>
                            <p>Low cost</p>
                            <p>The average Spectre transaction fee hovers around $0.000021.</p>
                        </div>
                        <img src={CoinSpectre} loading="lazy" alt='coin' />
                </Coin>
                <Coin>
                        <div className='coin-decs'>
                            <p>Fast</p>
                            <p>Spectre is all about speed, with 3 second block times. And as hardware gets faster, so does the network.</p>
                        </div>
                        <img src={CoinSpectre} loading="lazy" alt='coin' />
                </Coin>
                <Coin>
                        <div className='coin-decs'>
                            <p>Friendly to earth</p>
                            <p>Unlike Bitcoin, which uses a PoW consensus algorithm that consumes a lot of electrical energy. Spectre uses PoS consensus algorithm, this completely solves the above problem, 100% friendly to earth</p>
                        </div>
                        <img src={CoinSpectre} loading="lazy" alt='coin' />
                </Coin>
                <Coin>
                        <div className='coin-decs'>
                            <p>High security</p>
                            <p>With PoS consensus algorithm, Spectre provides absolute guarantee of safety.</p>
                        </div>
                        <img className='coin-4-img' src={CoinSpectre} loading="lazy" alt='coin' />
                </Coin>
            </div>
        </div>
    </StyledDiv>
  )
}
