import React from 'react'
import styled from 'styled-components'
// import { useTranslation } from 'react-i18next'

// import Spectre_icon from '../../assets/images/icons/Spectre_chain_logo_color.svg'

const StyledDiv = styled.div`
    width: 100%;
    /* height: 380px; */
    height: fit-content;
    background: #000;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    p, a, span {
        color: #fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
    }
    .logo {
        /* width: 100%; */
        display: flex;
        margin: 0 auto;
        padding: 50px 0 30px;
        > img, p {
            cursor: pointer;
        }
        > p {
            margin: auto 0;
            padding-left: 8px;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .wapper-content {
        /* margin: 0 126px; */
        /* width: 100%; */
        margin: auto;
        display: flex;
        justify-content: space-between;
        .content-info {
            width: fit-content;
            text-align: left;
            > p {
                font-size: 16px;
                line-height: 38px;
                color: #F2F2F2;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .copyright {
        /* display: flex; */
        text-align: center;
        margin: auto;
        font-size: 20px;
    }
    @media (min-width: 1441px) {
        .logo {
            max-width: 1200px;
            margin: auto;
        }
        .wapper-content {
            max-width: 1200px;
            margin: auto;
        }
    }
    @media (max-width: 1440px) {
        .logo, .wapper-content {
            max-width: 1100px;
        }
    }
    @media (max-width: 1200px) {
        .logo, .wapper-content {
            max-width: 920px;
        }
    }
    @media (max-width: 992px) {
        .logo {
            /* margin: 0 80px; */
            max-width: 720px;
        }
        .wapper-content {
            /* margin: 0 80px; */
            max-width: 720px;
        }
    }
    @media (max-width: 768px) {
        .logo {
            /* margin: auto; */
            max-width: 540px;
        }
        .wapper-content {
            /* margin: 0 30px; */
            max-width: 540px;
        }
    }
    @media (max-width: 576px) {
        /* height: fit-content; */
        /* padding-bottom: 20px; */
        .logo {
            margin: 0 22px;
        }
        .wapper-content {
            flex-wrap: wrap;
            margin: 0 22px;
            .content-info {
                width: 34%;
            }
            .content-info:nth-child(2) {
                padding-left: 10%;
            }
            .content-info:nth-child(4) {
                padding-left: 10%;
            }
        }
    }
`;

const Footer = () => {

    return (
        <StyledDiv>
            {/* <div className='logo'>
                <img src={Spectre_icon} alt='logo' />
                <p>SPECTRE</p>
            </div> */}
            {/* <div className='wapper-content'>
                <div className='content-info'>
                    <p>BUILD</p>
                    <p>Docs</p>
                    <p>GitHub</p>
                    <p>Chat on Discord</p>
                    <p>Status</p>
                </div>
                <div className='content-info'>
                    <p>APPLY</p>
                    <p>助成金</p>
                    <p>Collective</p>
                    <p>求人情報</p>
                </div>
                <div className='content-info'>
                    <p>LEARN</p>
                    <p>Blog</p>
                    <p>Podcast</p>
                    <p>Videos</p>
                    <p>ネットワーク統計</p>
                </div>
                <div className='content-info'>
                    <p>OTHER</p>
                    <p>Break Solana</p>
                    <p>Disclaimer</p>
                    <p>Press & Brand</p>
                </div>
            </div> */}
            <div className='copyright'>
                <p>Copyright © 2022 Spectre team. All Rights Reserved.</p>
            </div>
        </StyledDiv>
  )
}

export default Footer;
