import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'


const INIT_LANGUAGE = 'en'

i18next
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: INIT_LANGUAGE,
        fallbackLng: INIT_LANGUAGE,
        interpolation: { escapeValue: false },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json'
        },
        react: { useSuspense: false }
    });

export default i18next;

