import React from 'react';

import Header from './header';
import Bg from './bg';
import Lbank from './lbank';
import WhyCrappo from './why-crappo';
import Features from './features';
import Footer from './footer';

const HomePage = () => {
  return (
    <>
        <Header />
        <Lbank />
        <WhyCrappo />
        <Bg />
        <Features />
        <Footer />
    </>
  )
}

export default HomePage;
