import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';

import HomePage from './components/home';
import Network from './components/network/network';

const StyledDiv = styled.div`
/* HTML5 display-role reset for older browsers */
	width: 100%;
	height: 100%;
	max-width: 1920px;
	margin: auto;
	/* overflow-y: scroll; */
	div {
		box-sizing: border-box;
	}
	body {
		line-height: 1;
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	body,p ,a {
		margin: 0;
	}

	body::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		background-color: var(--text-medium);
    	// border tạo ảo giác margin-right
		border-right: solid 6px #36393F;
    }

    body::-webkit-scrollbar {
    	// width: 8px ( trừ đi vì border-right)
		width: 14px;
    	/* background-color: #fff; */
    }

    body::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		background-color: var(--text-dark);
		// border tạo ảo giác margin-right
		border-right: solid 6px #36393F;
    }


`;


function App() {
  return (
		<StyledDiv className="scrollbar">
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/network' element={<Network />} />
			</Routes>
		</StyledDiv>
  );
}

export default App;
