import React from 'react'
import styled from 'styled-components';

import MenuSpectre from '../menu/menu';

const StyledDiv = styled.div`
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(110.27deg,#08073D 12.69%,#000000 100%,rgba(0,0,0,0) 100%);
    a {
        text-decoration: none;
    }
    p, a, span, li, ul {
        color: #fff;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
    }
`;

const NetworkContent = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    margin-top: 78px;

    @media (max-width: 1440px) {
        max-width: 1100px;
    }
    @media (max-width: 1200px) {
        max-width: 920px;
    }
    @media (max-width: 998px) {
        max-width: 720px;    
    }
    @media (max-width: 768px) {
        max-width: 540px;    
        .network-title {
            .title-name {
                font-size: 40px !important;
                padding: 6px 0;
            }
        }
    }
    @media (max-width: 576px) {
        padding: 0 22px;
        .network-title {
            .title-name {
                font-size: 32px !important;
            }
        }
    }

    .network-title {
        .title-name {
            font-size: 64px;
            font-weight: 700;
            line-height: 150%;
        }
        .title-text {
            font-size: 18px;
            color: #E0E0E0;
        }
    }
    .network-link {
        > p {
            font-size: 20px;
            margin-top: 32px;
        }
        > ul {    
            > li {
                list-style-type: disc !important;
                > p:nth-child(1) {
                    color: #ccc;
                }
                > .link-item {
                    display: list-item;
                    color: #ccc;
                    list-style-type: circle !important;
                    margin-left: 34px;
                    padding: 6px 0;
                    > span, a {
                        color: #ccc;
                    }
                }
            }
        }
    }

`;

const Network = () => {

    // const [isActive, setActive] = useState(false)

    // const handleClick = () => {
    // setActive(!isActive)
    // }
    return (
    <>
    <StyledDiv>
        <MenuSpectre />
        <NetworkContent>
            {/* <div className='network-title'>
                <p className='title-name'>RPC PROVIDER</p>
                <p className='title-text'>Not only is Spectre ultra-fast and low cost, it is censorship resistant. The Spectre network is spread over thousands of independent nodes — which means that your transactions are always safe.</p>
            </div> */}
            <div className='network-link'>
            <p>RPC PROVIDER</p>
            <ul>
                <li>
                    <p>[mainnet]</p>
                    <p className='link-item'><span>Network Name: Spectre Chain</span></p>
                    <p className='link-item'><span>RPC URL: https://spectre-rpc.io</span></p>
                    <p className='link-item'><span>Chain ID: 39</span></p>
                    <p className='link-item'><span>Currency Symbol: SPC</span></p>
                </li>
                <li>
                    <p>[testnet]</p>
                    <p className='link-item'><span>Network Name: Spectre Chain Testne</span></p>
                    <p className='link-item'><span>RPC URL: https://testnet.spectre-rpc.io</span></p>
                    <p className='link-item'><span>Chain ID: 55</span></p>
                    <p className='link-item'><span>Currency Symbol: SPC</span></p>
                </li>
            </ul>
            <p>BlockScan</p>
            <ul>
                <li>
                    <p>[mainnet]</p>
                    <p className='link-item'><a href="https://spectre-scan.io/" target="_blank" rel="noreferrer">https://spectre-scan.io</a></p>
                </li>
                <li>
                    <p>[testnet]</p>
                    <p className='link-item'><a href="https://testnet.spectre-scan.io" target="_blank" rel="noreferrer">https://testnet.spectre-scan.io</a></p>
                </li>
                {/* <li>
                    <p>Spectre</p>
                    <p className='link-item'><span>htttps://google.com</span></p>
                    <p className='link-item'><span>htttps://google.com</span></p>
                    <p className='link-item'><span>htttps://google.com</span></p>
                </li> */}
            </ul>
            </div>
        </NetworkContent>
    </StyledDiv>
    </>
  )
}

export default Network;
